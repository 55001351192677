@font-face {
    font-family: 'Perpetua';
    src: url('/fonts/Perpetua.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Perpetua';
    src: url('/fonts/Perpetua-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Perpetua';
    src: url('/fonts/Perpetua-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Perpetua';
    src: url('/fonts/Perpetua-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir-Heavy';
    src: url('/fonts/Avenir-Heavy.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir-Medium';
    src: url('/fonts/Avenir-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir-Roman';
    src: url('/fonts/Avenir-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir-Book';
    src: url('/fonts/Avenir-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
