
.page {
  &.category-item,
  &.collection-item {
    .slick-slider {
      margin: 0 !important;
    }
  }
}

.page.home {
  .slick-slide {
    height: auto !important;
  }
}
