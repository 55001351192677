.lang-tc {
  /* Overwrite mm-menu */
  .mm-menu .mm-listview li {
    &.index a .item-vert .text {
      font-family: 'hanamin', 'Perpetua';
      letter-spacing: 3px !important;
      text-indent: 3px !important;
    }
    &.item a .item-vert .text {
      letter-spacing: 5px !important;
      text-indent: 5px !important;
    }
  }
  .menu {
    &.categories .category-list .category-item {
      letter-spacing: 6px;
      text-indent: 6px;
    }
    &.collections .collection-list .collection-item .title-container .title-vert .title {
      letter-spacing: 6px;
      text-indent: 6px;
    }
  }
  .page {
    &.the-brand,
    &.social-responsibilities,
    &.services,
    &.contact-us,
    &.the-boutique,
    &.events {
      .context .description {
        text-align: justify;
        .para {
          text-align: justify;
        }
      }
    }
    &.category-index {
      footer {
        letter-spacing: 2px;
      }
    }
    &.category-item {
      .hero-details {
        .description {
          line-height: 20px;
          letter-spacing: 1.5px;
        }
      }
    }
    &.collection-item {
      .hero-details {
        .title {
          font-family: 'hanamin';
          letter-spacing: 3px;
        }
        .description {
          line-height: 20px;
          text-align: justify;
        }
      }
    }

    .context {
      .title{
        font-family: 'hanamin';
      }
      .subtitle{

      }
    }
    &.home {
      .landing .scroll-hint {
        .title {
          font-size: 16px;
          line-height: 32px;
          letter-spacing: 2.5px;
        }
      }
    }
    &.the-boutique {
      .context {
        .subtitle {
          font-size: 16px;
        }
        .description {
          letter-spacing: 1px;
        }
      }
    }
    &.events {
      .desc-container {
        .event-title {
          letter-spacing: 1px;
        }
        .event-desc {
          letter-spacing: 1px;
        }
      }
    }
    &.the-brand {
      .context {
        .title{
          font-family: 'Perpetua';
        }
      }
    }
    &.social-responsibilities {
    }
    &.services {
      .description {
        .header {
          letter-spacing: 1px;
        }
        .para {
          letter-spacing: 1px;
        }
      }
      .context .description .para.left {
        text-align: left;
        ol {
          padding-left: 20px;
        }
      }
    }
    &.contact-us {
      .title {
        letter-spacing: 3px;
      }
      .para-title {
        letter-spacing: 2px;
      }
      .para-desc {
        letter-spacing: 1px;
      }
    }
    &.services  {
    }
  }
  header.breadcrumb {
    letter-spacing: 2px;
  }
  section header {
    &.highlight .title {
      font-family: 'hanamin', 'Perpetua';
      letter-spacing: 3px;
    }
    .title {
      letter-spacing: 5px;
    }
  }
  .force-perpetua {
    font-family: 'Perpetua' !important;
  }
}
