html, body {
  /* It's neccessary to set relative-position to a parent container */
  position: relative;
  background: #534741;
  width: 100%;
  height: 100%;
}
/* Global */
#universe {
  /* Anti-alias */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 1px;

  position: absolute;
  width: 100%;
  background: #534741;
  .full {
    width: 100%;
  }
  .social-media {
    height: 35px;
    text-align: center;
    ul {
      list-style: none;
      margin: 0 auto;
      padding: 0;
      li {
        &:active {
          opacity: .5;
        }
        background-size: 35px 35px;
        width: 35px;
        height: 35px;
        display: inline-block;
        outline: 0px solid blueviolet;
        margin: 0 18px;
        &.mail {
          background-image: url(/images/scaled/icon-mail-large@2x.png);
        }
        &.facebook {
          background-image: url(/images/scaled/icon-facebook-large@2x.png);
        }
        &.instagram {
          background-image: url(/images/scaled/icon-instagram-large@2x.png);
        }
        &.pinterest {
          background-image: url(/images/scaled/icon-pinterest-large@2x.png);
        }
      }
    }
  }
  section.menu {
    header {
      &:active {
        opacity: .7;
      }
    }
    &.focused {
      opacity: .5;
    }
  }
}
header {
  &.main {
    border-bottom: 1px solid rgba(83,71,65, .7);
    height: 70px;
    background: #736357;
    .hero-text {
      position: relative;
      background-image: url(/images/scaled/header-hero-text@2x.png);
      background-size: 150px 34px;
      width: 150px;
      height: 34px;
      font-size: 0;
      margin: 0 auto;
      top: 17px;
    }
    .burger-icon {
      display: inline-block;
      position: absolute;
      background-image: url(/images/scaled/burger-icon-new@2x.png);
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-position: 20px 27px;
      /* 60x70 for touch area */
      width: 60px;
      height: 70px;
      margin: 0;
      top: 0;
      left: 0;
      &:active {
        opacity: .5;
      }
    }
    .language-toggler {
      display: inline-block;
      position: absolute;
      background-image: url(/images/scaled/down-arrow-brown@2x.png);
      background-size: 12px 6px;
      background-repeat: no-repeat;
      background-position: 19px 33px;
      /* 50x60 square for touch area */
      width: 50px;
      height: 70px;
      margin: 0;
      top: 0;
      right: 0;
      transition: transform .35s;
      transform: rotateZ(0deg);
      transform-origin: center;
      &.toggled {
        /* transform: rotateZ(180deg); */
        transform: scaleY(-1);
        opacity: .5;
      }
    }
  }
}
.lang-select {
  width: 100%;
  position: absolute;
  height: 42px;
  background: #C7B299;
  border: 1px solid rgba(0,0,0,.5);
  top: 0;
  transition: top .2s;
  box-sizing: border-box;
  z-index: 998;
  &.toggled {
    top: 70px;
  }
  .title {
    width: 50%;
    height: 100%;
    display: table;
    font-family: 'Avenir-Book';
    box-sizing: border-box;
    color: #534741;
    display: inline-block;
    vertical-align: top;
    padding-top: 13px;
    font-size: 12px;
    text-align: center;
    color: #534741;
  }
  .button {
    width: 25%;
    height: 100%;
    display: table;
    font-family: 'Avenir-Book';
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    padding-top: 13px;
    font-size: 12px;
    text-align: center;
    color: #534741;
    border-left: 1px solid rgba(0,0,0,.5);
    &:active {
      color: #fff;
    }
    &.tc {
      padding-top: 13px;
      letter-spacing: 8px;
      text-indent: 8px;
    }
  }
}

section header {
  border-top: 1px solid rgba(0,0,0,.25);
  width: 100%;
  height: 45px;
  .title {
    font-family: 'Avenir-Book';
    font-size: 14px;
    letter-spacing: 1.5px;
    line-height: 45px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
  }
  .subtitle {
    text-align: center;
    font-family: 'Avenir-Book';
    font-size: 10px;
    line-height: 10px;
    margin: -6px 0;
    color: #534741;
  }
  .arrow {
    background-image: url(/images/scaled/down-arrow-white@2x.png);
    transform: scaleY(1);
    background-size: 12px 6px;
    width: 12px;
    height: 6px;
    margin: -5px auto;
  }

  /* Default Gradient */
  background: #534741;
  background: -moz-linear-gradient(left, #534741 0%, #998675 50%, #534741 100%);
  background: -webkit-linear-gradient(left, #534741 0%,#998675 50%,#534741 100%);
  background: linear-gradient(to right, #534741 0%,#998675 50%,#534741 100%);

  &.highlight {
    border-bottom: 0px !important;
    &.expanded {
      border-bottom: 1px solid rgba(0,0,0,.25);
      .title {
        color: #000 !important;
      }
      .arrow {
        background-image: url(/images/scaled/down-arrow-black@2x.png) !important;
        transform: scaleY(-1) !important;
      }
    }
    .title {
      font-family: 'Perpetua';
      font-size: 18px;
    }
    height: 60px;
    line-height: 45px;
    background: -moz-linear-gradient(left, #534741 0%, #b3b3b3 50%, #534741 100%);
    background: -webkit-linear-gradient(left, #534741 0%,#b3b3b3 50%,#534741 100%);
    background: linear-gradient(to right, #534741 0%,#b3b3b3 50%,#534741 100%);
  }
}
section.landing {
  .slider {
    width: 100%;
    height: auto;
    min-height: 280px; /* Preserving the height of slider */
    background: #000;
    outline: 1px solid rgba(0,0,0,.3);
    .slide {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .slick-dots {
      bottom: -42px !important;
    }
  }
  .scroll-hint {
    &:active {
      color: #fff;
    }
    height: 65px;
    color: #c7b299;
    text-align: center;
    line-height: 18px;
    font-size: 11px;
    .arrow {
      background-image: url(/images/scaled/down-arrow-brown@2x.png);
      background-size: 12px 6px;
      width: 12px;
      height: 6px;
      margin: 10px auto;
    }
  }
}
section.the-boutique {
  .slider {
    img {
      width: 100%;
    }
    font-size: 0;
    height: auto;
    background: #000;
  }
}
section.collections {
  .collection-list {
    .collection-item {
      &.first {
        border-top: 0px;
      }
      &:active {
        color: #fff;
        .image {
          opacity: .7;
        }
      }
      font-size: 0;
      border-top: 1px solid rgba(#666,.35);
      height: 65px;
      background: #000;
      color: #C7B299;
      width: 100%;
      .image {
        display: inline-block;
        outline: 0px solid blue;
        width: 55%;
        height: 100%;
        margin: 0;
        background-size: 177px 65px;
        background-repeat: no-repeat;
        background-position: center;
        &.angel {
          background-image: url(/images/scaled/collections-cover-01@2x.jpg);
        }
        &.bethlehem {
          background-image: url(/images/scaled/collections-cover-02@2x.jpg);
        }
        &.galene {
          background-image: url(/images/scaled/collections-cover-03@2x.jpg);
        }
        &.garden {
          background-image: url(/images/scaled/collections-cover-04@2x.jpg);
        }
        &.voyage {
          background-image: url(/images/scaled/collections-cover-05@2x.jpg);
        }
        &.cross {
          background-image: url(/images/scaled/collections-cover-06@2x.jpg);
        }
      }
      .title-container {
        display: inline-block;
        width: 45%;
        height: 100%;
        vertical-align: top;
        .title-vert {
          display: table;
          width: 100%;
          height: 100%;
          .title {
            font-family: 'Avenir-Book';
            display: table-cell;
            vertical-align: middle;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
section.categories {
  .category-list {
    background: #000;
    .category-item {
      font-family: 'Avenir-Book';
      height: 65px;
      line-height: 65px;
      text-align: center;
      margin: 0 85px;
      background: #000;
      color: #C7B299;
      text-transform: uppercase;
      &:active {
        color: #fff;
      }
      border-top: 1px solid rgba(#666, .35);
      &.first {
        border-top: 0px;
      }
    }
  }
}
section.category-index {
  .category-cell-list {
    width: 100%;
    font-size: 0;
    .category-cell {
      width: 50%;
      /* Here is a trick to keep the same ratio between width and height
        http://stackoverflow.com/questions/21537806/css-height-same-as-width */
      padding-top: 50%;
      display: inline-block;
      background: #000;
      outline: 1px solid #333; /* Darker from original Color #6B5F55*/

      /* Hero Image */
      background-image: url(/images/items/rings/31.png);
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  footer {
    background: #000;
    height: 40px;
    font-family: 'Avenir-Book';
    font-size: 12px;
    line-height: 40px;
    text-align: center;
    color: rgba(#fff,.5);
    &:active {
      color: #fff;
    }
  }
}
section.category-item, section.collection-item {
  .hero-image-container {
    position: relative;
    height: auto;
    padding: 10px 10px 30px 10px;
    box-sizing: border-box;
    text-align: center;
    vertical-align: top;
    img.slick-slide {
      position: relative;
      max-width: 100%;
      height: 100%;
    }
    .arrow {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      background-size: 8px 15px;
      width: 8px;
      height: 15px;
      &.left {
        background-image: url(/images/scaled/side-arrow-left@2x.png);
        left: 10px;
      }
      &.right {
        background-image: url(/images/scaled/side-arrow-right@2x.png);
        right: 10px;
      }
    }
    .pagination {
      position: absolute;
      bottom: 15px;
      left: 0;
      right: 0;
      margin: 0 auto;
      font-family: 'Avenir-Book';
      color: #736357;
      text-align: center;
      font-size: 10px;
      .current {
        color: #000;
      }
    }
  }
  .hero-details {
    padding: 20px;
    background: #000;
    font-family: 'Avenir-Book';
    color: #C7B299;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    margin: 0 auto;
    width: auto;
    .title {

    }
    .description {
      padding: 0 0 40px 0;
    }
  }
}
section.collection-item {
  .hero-image-container {
    background: #000;
    border-bottom: 1px solid rgba(#C7B299, .25);
    padding: 0;
    .pagination {
      .current {
        color: #fff;
      }
    }
  }
  .hero-details {
    .title {
      font-family: 'Perpetua';
      color: #C7B299;
      text-align: center;
      font-size: 24px;
      padding: 10px 0 30px 0;
    }
    .description {
      text-align: center;
      font-size: 14px;
    }
  }
}
footer {
  .back-to-top {
    border-top: 1px solid rgba(#998675, .6);
    height: 70px;
    text-align: center;
    figure {
      display: inline-block;
      margin: 25px auto 20px auto;
      background-image: url(/images/scaled/up-arrow@2x.png);
      background-size: 17px 9px;
      width: 17px;
      height: 9px;
    }
    &:active {
      figure {
        opacity: .5;
      }
    }
  }
  .logo {
    .galene-logo {
      background-image: url(/images/scaled/galene-logo@2x.png);
      background-size: 50px 55px;
      width: 50px;
      height: 55px;
      margin: 55px auto 20px auto;
    }
  }
  .copy-rights {
    height: 50px;
    font-size: 10px;
    line-height: 17px;
    text-align: center;
    color: #B3B3B3;
  }
}
header.breadcrumb {
  background: #45272A;
  height: 40px;
  font-family: 'Avenir-Book';
  font-size: 12px;
  text-transform: uppercase;
  line-height: 40px;
  text-align: center;
  color: #999;
  &:active {
    color: #fff;
  }
}
.page {
  &.home {

  }
  &.the-brand,
  &.social-responsibilities,
  &.services,
  &.contact-us,
  &.the-boutique,
  &.events,
  &.legal-terms
  {
    .hero-image {
      width: 100%;
      border-bottom: 1px solid rgba(0,0,0,.1);
    }
    .context {
      padding: 40px 20px;
      color: #736357;
      .title, .subtitle {
        text-align: center;
        font-size: 26px;
        margin-bottom: 10px;
        font-family: 'Perpetua';
      }
      .subtitle {
        font-size: 24px;
        font-style: italic;
        margin-bottom: 25px;
      }
      .description {
        letter-spacing: 0.5px;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        font-family: 'Avenir-Book';
      }
    }
  }
  &.services {
    .context {
      .title {
        margin-bottom: 20px;
      }
      .description {
        .header {
          text-align: center;
          margin-bottom: 10px;
          color: #000;
        }
        .para {
          text-align: center;
        }
      }
    }
  }
  &.legal-terms {
    .context {
      .description .toggler {
        position: relative;
        text-align: left;
        margin-top: 20px;
        border-bottom: 1px solid rgba(0,0,0,.1);
        line-height: 36px;
        color: #000;
        text-transform: uppercase;
        .arrow {
          position: absolute;
          top: 0;
          right: 0;
          background-image: url('/images/scaled/right-arrow@2x.png');
          background-size: 8px 11px;
          width: 8px;
          height: 11px;
          margin: 10px 10px;
          transition: transform .25s;
          transform: rotateZ(0deg);
          &.toggled {
            transform: rotateZ(90deg);
          }
        }
      }
      .terms-container {
        .header {
          padding: 15px 0 5px 0;
          text-align: left;
          color: #000;
          line-height: 20px;
        }
        .desc {
          text-align: justify;
        }
      }
    }
  }
  &.contact-us {
    background: #000;
    .title {
      color: #fff;
    }
    .para-title {
      color: #ccc;
      font-style: normal;
      letter-spacing: 0.5px;
      font-size: 14px;
      font-weight: bold;
      line-height: 24px;
      text-align: center;
      font-family: 'Avenir-Book';
      margin: 35px 0 25px 0;
    }
    .para-desc {
      color: #ccc;
      letter-spacing: 0.5px;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      font-family: 'Avenir-Book';
    }
    a {
      color: #C2B49A;
      text-decoration: none;
    }
  }
  &.the-boutique {
    .context {
      padding: 25px 20px 40px 20px;
    }
    .title {
      color: #fff;
    }
    .subtitle {
      color: #fff;
    }
    .description {
      color: #c7b299;
    }
    .gap {
      margin-top: 40px;
    }

    /*
    .slick-slider {
      .slick-dots li {
        margin: 0 !important;
        button:before {
          opacity: 1 !important;
          color: #fff !important;
        }
        &.slick-active {
          button:before {
            color: #000 !important;
          }
        }
      }
    }
    */
    .slick-slider {
      outline: 1px solid rgba(0,0,0,.3);
      .slick-list {
        .slick-slide {
          min-height: 280px;
        }
      }
      .slick-dots li {
        margin: 0 !important;
        button:before {
          color: #fff !important;
        }
      }
    }
  }
  &.events {
    background: #2a2421;
    .event-cover {
      width: 100%;
      outline: 1px solid rgba(0,0,0,.3);
    }
    .desc-container {
      padding: 20px 20px 40px 20px;
      .event-title {
        letter-spacing: 0.5px;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #fff;
        font-family: 'Avenir-Book';
        margin-bottom: 25px;
      }
      .event-desc {
        letter-spacing: 0.5px;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #cccccc;
        font-family: 'Avenir-Book';
      }
    }
  }
}
.effect {
  &.bottom-shadow {
    position: relative;
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,.35);
    z-index: 999;
  }
  &.gradient-998675 {
    background: #998675;
    background: -moz-linear-gradient(left, #998675 0%, #ffffff 50%, #998675 100%);
    background: -webkit-linear-gradient(left, #998675 0%,#ffffff 50%,#998675 100%);
    background: linear-gradient(to right, #998675 0%,#ffffff 50%,#998675 100%);
  }
  &.gradient-b3b3b3 {
    background: #b3b3b3;
    background: -moz-linear-gradient(left, #b3b3b3 0%, #e6e6e6 50%, #b3b3b3 100%);
    background: -webkit-linear-gradient(left, #b3b3b3 0%,#e6e6e6 50%,#b3b3b3 100%);
    background: linear-gradient(to right, #b3b3b3 0%,#e6e6e6 50%,#b3b3b3 100%);
  }
  &.gradient-534741 {
    background: #000000;
    background: -moz-linear-gradient(left, #000000 0%, #534741 50%, #000000 100%);
    background: -webkit-linear-gradient(left, #000000 0%,#534741 50%,#000000 100%);
    background: linear-gradient(to right, #000000 0%,#534741 50%,#000000 100%);
  }
}
.hidden {
  display: none;
}
