.mm-menu {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .mm-panels {
    background: #000;
  }
  .mm-listview {
    background: #000;
    li {
      a {
        padding: 0 !important;
        margin: 0 !important;
        letter-spacing: 1.5px;
      }
      height: 50px !important;
      text-align: center !important;
      &:not(.mm-divider):after {
        left: 0 !important;
      }
    }
    .mm-panel {
      background: #000 !important;
    }
    .index {
      font-family: 'Perpetua';
      font-size: 18px;
      letter-spacing: 1.5px;
      background: #333;
    }
    .item {
      font-family: 'Avenir-Book';
      letter-spacing: 1.5px;
      font-size: 14px;
      color: #c7b299;
      &:active {
        color: #fff;
      }
      background: #000;
      border-top: 1px solid rgba(#c7b299, .2);
      &.first {
        border-top: 0px;
      }
      margin: 0 60px;
      &::after {
        display: none !important;
      }
    }
    .index, .item {
      a {
        width: 100%;
        height: 100%;
        .item-vert {
          display: table;
          width: 100%;
          height: 100%;
          .text {
            display: table-cell;
            vertical-align: middle;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
